<template>
    <div class="no-box" v-loading="pageLoading" id="printdiv">
        <el-card header="合同回签要求" class="left-card" style="margin-bottom:20px;">
            <div style="text-align:left; width:100%; margin:0 auto;" v-html="content"></div>
            <ul class="upload-list info">
                <li v-for="(item,index) in fileList" :key="index">
                    <el-link type="primary">{{item.file_name}}</el-link>
                    <span class="icon">
                        <i class="el-icon-view" @click="viewFile(item.file_name,item.file_url)"></i>
                        <a :href="item.file_url" :download="item.file_name"><i class="el-icon-download"></i></a>
                    </span>
                </li>
            </ul>



            <!-- <div class="info-list">
                <span v-for="(item,index) in fileList" :key="index"><a :href="item.file_url" target="_blank">{{item.file_name}}</a></span>
            </div> -->
        </el-card>
        <el-card header="合同文件" class="left-card" style="margin-bottom:20px;padding-bottom:50px;">
            <ul class="upload-list">
                <li v-for="(item,index) in contractList" :key="index">
                    <div>
                        <el-link type="primary">{{item.file_name}}</el-link>
                        <span class="icon">
                            <i class="el-icon-view" @click="viewFile(item.file_name,item.file_url)"></i>
                            <a :href="item.file_url" :download="item.file_name"><i class="el-icon-download"></i></a>
                        </span>
                    </div>
                    
                </li>
            </ul>


            <!-- <ul class="upload-list">
                <li v-for="(item,index) in contractList" :key="index">
                    <el-link @click="openFile(item.file_name,item.file_url)" type="primary">{{item.file_name}}</el-link>
                </li>
            </ul> -->
        </el-card>
    </div>
</template>
<script >
import { getContractNoticeInfo, getContractDetailInfo } from "@/api";

export default {
    name: 'ContractInstructions',
    data(){
        return {
            pageLoading: false,
            content: '',
            fileList: [],
            contractList:[],
            isDisabled: true,
            printOper:null
        }
    },
    created(){
        const id = parseInt(this.$route.params && this.$route.params.id)
        this.getData();
        this.getContractFiles(id)
        const _this = this

        // window.addEventListener('beforeprint',_this.beforePrint)
        // window.addEventListener('afterprint',_this.afterPrint)
    },
    methods: {
        getData(){
            this.pageLoading = false
            getContractNoticeInfo().then((res)=>{
                const data = res.data.info;
                this.content = data.content;
                this.fileList = data.file_list
            }).catch(err => {
                // console.log(err,'异常')
            }).finally(()=>{
                this.pageLoading = false
            })
        },

        getContractFiles(id){
            getContractDetailInfo({contract_id:id}).then((res)=>{
                this.contractList = res.data.file_list||[]
            }).catch(err => {
                // console.log(err,'异常')
            }).finally(()=>{
                this.pageLoading = false
            })
        },

        //预览文件
        viewFile(name, url){
            const type = name.replace(/.*\./, "").toLocaleUpperCase()
            if(type==='PDF' || type==='JPG' || type === 'JPEG' || type === 'PNG'){
                window.open(url)
            } else {
                window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
            }
        },

        //下载文件
        // openFile(){
        //     window.open(url)
        // },

        // beforePrint() {
        //     if(document.getElementById('printdiv')){
        //         document.body.innerHTML=document.getElementById('printdiv').innerHTML;
        //     }
        // },
        // afterPrint(){
        //    window.location.reload();
        // },
    }
}
</script>
<style lang="scss" scoped>
.no-box{
    padding: 0;
    box-shadow: none;
    .info-list {
        margin-top: 50px;
        span{
            margin-right: 40px;
            &:last-child{margin-right: 0;}
        }
    }
    .upload-list{
       li{
           font-size: 14px;
           line-height: 34px;
           .el-link.is-underline:hover:after{
               border: 0;
           }
           i{
               color: #606266;
               font-size: 16px;
               margin-top: 4px;
           }
           span{
               float: right;
           }


           &>div{
                display: inline-block;
                min-width: 340px;
                padding: 0 10px;

                .el-link{
                    margin-right: 86px;
                }

                &:hover{
                    background-color: #f5f7fa;
                }
           }
           
       }
    }
    .upload-list.info{
        margin-top: 50px;
        margin-bottom: 10px;
        li{
            display: inline-block;
            padding-left: 12px;
            padding-right: 110px;
            position: relative;
            &:hover{
                background-color: #f5f7fa;
            }
            .icon{
                top: 0;
                right: 4px;
                position: absolute;
            }
        }
    }
}
</style>
